import React from 'react';
import { StickyProvider } from 'contexts/app/app.provider';
import Seo from 'components/seo';
import Banner from 'sections/banner';
import WhyChoose from 'sections/why-choose';
import SalesInvestment from 'sections/sales-investment';
import Team from 'sections/team';
import RoadMap from 'sections/roadmap';
import CountDownBlock from 'sections/countdown';
import WorldwideUsers from 'sections/worldwide-user';
import OurWallet from 'sections/our-wallet';
import CallToAction from 'sections/call-to-action';
import Layout from 'components/layout';

import 'rc-drawer/assets/index.css';
import 'react-modal-video/css/modal-video.min.css';

export default function IndexPage() {
  return (
    <StickyProvider>
      <Layout>
        <Seo
          title="Peer2Panel - Renewable energy investing with blockchain"
          description="Peer2Panel allows investors to securely invest in blockchain tokens backed by physical solar panels, gaining competitive returns as well as high liquidity through our token marketplace. "
        />
        <Banner />
        <WhyChoose />
        {/*<SalesInvestment />*/}
        <Team />
        <RoadMap />
        {/*<CountDownBlock />*/}
        {/*<WorldwideUsers />*/}
        {/*<OurWallet />*/}
        <CallToAction />
      </Layout>
    </StickyProvider>
  );
}
